(function() {

    var $ = jQuery.noConflict();

    angular
        .module('mapApp')
        .controller('MapCtrl', MapCtrl);

    MapCtrl.$inject = ['$state', 'popupFactory'];

    function MapCtrl($state, popupFactory) {
        cdb1947 = (typeof cdb1947 != 'undefined') ? cdb1947 : {};

        cdb1947.map = function(config) {

            // Default Values
            $.extend(this, {
                center: new google.maps.LatLng(18, 120),
                zoom: 4,
                mapTypeControl: false,
                streetViewControl: false,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                cdbUsername: 'archive-1947',
                cdbTablename: 'stories_w_current'
            });

            if (config) {
                $.extend(this, config);
            }

            this.mapDiv = $('#' + this.mapId);
            this.containerDiv = $('#' + this.contentId);
            this.sql = new cartodb.SQL({
                user: this.cdbUsername
            });
            this.polyLines = [];
            this.selectedCity = "";
            this.view = 'view1';
            this.layer = null;

            /* Easy reuse for SQL queries */
            this.fieldMap = {
                view1: {
                    city: 'migrated_from_village_city_state_country',
                    color: 'hsl(25, 87%, 39%)',
                    snapshot: 'snap_from_id',
                    legendClass: 'from',
                    lat: 'migrated_from_latitude_coordinates',
                    lon: 'migrated_from_longitude_coordinates'
                },
                view2: {
                    city: 'migrated_to_village_city_state_country',
                    color: 'maroon',
                    snapshot: 'snap_to_id',
                    legendClass: 'to',
                    lat: 'migrated_to_latitude_coordinates',
                    lon: 'migrated_to_longitude_coordinates'
                },
                view3: {
                    city: 'current_residence___city_state_country',
                    color: '#809e4c',
                    legendClass: 'cur',
                    lat: 'current_residence_latitude_coordinates',
                    lon: 'current_residence_longitude_coordinates'
                }
            };

            // CartoCSS vars (smallest to largest)
            this.clusterVars = {
                from: ['f39653', 'f07924', 'd2600f', 'ba550d', 'a74c0c'],
                to: ['db5757', 'd22d2d', 'A82424', '932020', '821c1c'],
                cur: ['b0c68a', '99b667', '809E4C', '728d44', '677f3d']
            };

            // Placeholders for CartoCSS, SQL
            this.clusterStyles = {};
            this.clusterQueries = {};

            var me = this;

            /******************** Polylines ********************/
            this.clearPolyLines = function() {
                if (me.polyLines.length) {
                    me.polyLines.forEach(function(row) {
                        row.setMap(null);
                    });
                    me.polyLines = [];
                }
            };

            this.drawPolyLines = function(data) {
                me.clearPolyLines();
                data.forEach(function(row) {
                    var latFrom = row.migrated_from_latitude_coordinates,
                        latTo = row.migrated_to_latitude_coordinates,
                        lonFrom = row.migrated_from_longitude_coordinates,
                        lonTo = row.migrated_to_longitude_coordinates;

                    if (latFrom != latTo && lonFrom != lonTo) {

                        var coords = [new google.maps.LatLng(latFrom, lonFrom),
                            new google.maps.LatLng(latTo, lonTo)
                        ];

                        var baseIcon = {
                            path: "m -12.450346,-12.50014 -3.375898,3.359898 4.624586,6.728487 -13.698343,-2.071831 0,9.1878906 13.698343,-2.071832 -4.624586,6.7284884 3.375898,3.359899 L 0.09999956,0.11035887 z",
                            fillColor: '#000',
                            fillOpacity: 1,
                            scale: 1,
                            strokeColor: '#000',
                            strokeWeight: 1.5,
                            rotation: -90
                        };

                        var flightPath = new google.maps.Polyline({
                            path: coords,
                            clickable: false,
                            geodesic: true,
                            strokeColor: '#330000',
                            strokeOpacity: 1.0,
                            strokeWeight: 3,
                            icons: [{
                                icon: baseIcon
                            }]
                        });

                        animateLine(flightPath);
                        flightPath.setMap(me.map);
                        me.polyLines.push(flightPath);

                    }
                });
            };

            function animateLine(flightPath) {
                var count = 0;
                var myTimeout;
                var intervalSet = window.setInterval(function() {
                    count = (count + 1) % 200;
                    var icons = flightPath.get('icons');
                    icons[0].offset = (count / 1.99) + '%';
                    flightPath.set('icons', icons);
                    if (count === 199) {
                        clearInterval(intervalSet);
                    }
                }, 5);
            }

            this.prepCityDropdownQuery = function() {
                var cityField = me.fieldMap[me.view].city,
                    query =
                        "SELECT DISTINCT " + cityField + " " +
                        "AS city FROM stories_w_current ORDER BY " + cityField;

                me.sql.execute(query)
                    .done(function(data) {
                        me.cityDropdownQueryDone(data);
                    })
                    .error(function(errors) {
                        console.log("City dropdown query:" + err);
                    });
            };

            this.cityDropdownQueryDone = function(data) {
                if (me.resultHasData(data)) {
                    var cityDropdownHtml =
                        '<label class="cdb1947-city">City:</label>' +
                        '<select id="mySelect" name="mySelect">' +
                        '<option value="all">Select City</option>';

                    data.rows.forEach(function(row) {
                        cityDropdownHtml += '<option value="' + row.city + '">' + row.city + '</option>';
                    });

                    cityDropdownHtml += '</select>';

                    $('#cdb1947-legend-city-select').html(cityDropdownHtml);
                    $('#mySelect').change(me.cityDropdownChanged);
                }
            };

            this.cityDropdownChanged =function () {
                var city = $(this).val(),
                    $searchResults = $('#cdb1947-search-result');

                me.runNewSqlQuery(city, '', '');

                if (city === 'all') {
                    $searchResults.hide();
                } else {
                    $searchResults.show();
                }
            };

            this.resultHasData = function (data) {
                if (data.rows && data.rows.length > 0){
                    return true;
                }
            };

            this.updateStoriesAvailable = function(x, y) {
                $('#total-stories').html(y);

                if (x === null) {
                    $('.cdb1947-results').text(y);
                } else if (x == 1) {
                    $('#cdb1947-sort').hide();
                    $('.cdb1947-results').text(x);
                } else {
                    $('#cdb1947-sort').show();
                    $('.cdb1947-results').text(x);
                }
            };

            this.getAutoCompleteData = function() {

                var cityField = me.fieldMap[me.view].city,
                    query = "SELECT " + cityField + " as cellvalue, '" +
                    cityField + "' as fieldsource, 'Location' as header " +
                    "FROM stories_w_current " +
                    "UNION SELECT sponsor as cellvalue, 'sponsor' as fieldsource, 'Sponsor' as header " +
                    "FROM stories_w_current " +
                    "UNION SELECT interviewee as cellvalue, 'interviewee' as fieldsource, 'Interviewee' as header " +
                    "FROM stories_w_current " +
                    "ORDER BY header ASC, cellvalue";

                $.widget("custom.catcomplete", $.ui.autocomplete, {
                    _renderMenu: function(ul, items) {
                        var that = this,
                            currentKey = "";
                        $.each(items, function(index, item) {
                            if (item.key != currentKey) {
                                ul.append("<li class='ui-autocomplete-category'>" + item.header + "</li>");
                                currentKey = item.key;
                            }
                            that._renderItemData(ul, item);
                        });
                    }
                });

                me.sql.execute(query)
                    .done(function(data) {
                        if (me.resultHasData(data)) {
                            me.autocompleteQueryDone(data);
                        }
                    });
            };

            this.autocompleteQueryDone = function(data) {
                var queryResults = data.rows,
                    resultsArray = [];

                for (var i = 0; i < queryResults.length; i++) {
                    resultsArray.push({
                        key: queryResults[i].fieldsource,
                        header: queryResults[i].header,
                        value: queryResults[i].cellvalue
                    });
                }

                $("#cdb1947-tags").catcomplete({
                    source: function(request, response) {
                        var results = $.ui.autocomplete.filter(resultsArray, request.term),
                            predicate = me.autocompletePredicate();
                        response(results.filter(predicate));
                    },
                    appendTo: "#listSuggestions",
                    select: function(event, ui) {
                        var selectedValue = {
                            field: ui.item.key,
                            value: ui.item.value
                        };
                        me.runNewSqlQuery(selectedValue, '', '');
                    }
                });

            };

            this.autocompletePredicate = function() {
                return function() {
                    var counter = {
                        Location: 0,
                        Interviewee: 0,
                        Sponsor: 0
                    };
                    return function(item) {
                        counter[item.header] += 1;
                        return (counter[item.header] <= 100000);
                    };
                }();
            };

            this.populateSearchResults = function(data) {
                var total = data.rows,
                    pictureLeng, finalPicture = {},
                    resultsListInfo, fancyboxHTML, str,
                    initialImage, resultsListWrap, pictures, fancyboxHeader, thumbnailContainer, photoFields;

                if (me.view != "view3") {
                    me.drawPolyLines(data.rows);
                    $('.city-sort').show();
                } else {
                    $('.city-sort').hide();
                }

                $("#cdb1947-result-listing")
                    .html("")
                    .append('<div class="cdb1947-result-listing-wrapper"><ul id="itemContainer"></ul></div>');
                $('#cdb1947-tags').val('');
                $('.cdb1947-result-listing-wrapper').perfectScrollbar({
                    wheelSpeed: 20,
                    wheelPropagation: false
                });

                for (var i = 0; i < total.length; i++) {
                    resultsListInfo = '';
                    fancyboxHTML = '';
                    str = '';
                    initialImage = '';
                    resultsListWrap = '';
                    pictures = [];
                    fancyboxHeader = '';
                    thumbnailContainer = '';
                    photoFields = [total[i].interviewer_photo_link_1, total[i].interviewer_photo_link_2, total[i].interviewer_photo_link_3, total[i].interviewer_photo_link_4];

                    if (total[i].interviewer_photo_link_1) {
                        initialImage = total[i].interviewer_photo_link_1;
                        pictures.push({
                            href: initialImage
                        });
                    }
                    if (total[i].interviewer_photo_link_2) {
                        var img1 = total[i].interviewer_photo_link_2;
                        pictures.push({
                            href: img1
                        });
                    }
                    if (total[i].interviewer_photo_link_3) {
                        var img2 = total[i].interviewer_photo_link_3;
                        pictures.push({
                            href: img2
                        });
                    }
                    if (total[i].interviewer_photo_link_4) {
                        var img3 = total[i].interviewer_photo_link_4;
                        pictures.push({
                            href: img3
                        });
                    }
                    var intervieweeName = total[i].interviewee;

                    finalPicture[intervieweeName] = pictures;

                    if (pictures.length !== 0) {
                        $.each(photoFields, function(index, val) {
                            if (val) {
                                var fancyboxThumbHtml = "<a class='img-fancybox' rel='gallery" + i + "' href='" + val + "'><img src='" + val + "' /></a>";
                                thumbnailContainer += fancyboxThumbHtml;
                            }
                        });
                    }

                    if (total[i].interviewee) {
                        str = '<p><strong>' + total[i].interviewee + '</strong></p>';
                        resultsListInfo += str;
                        fancyboxHeader += '<h1>' + total[i].interviewee + '</h1>';
                    }

                    if (total[i].interviewee_date_of_birth) {
                        var year = total[i].interviewee_date_of_birth;
                        var age = 0;
                        if (year < 1947) {
                            age = 1947 - year;
                            str = '<p><strong>Age in 1947: </strong>' + age + '</p>';
                        } else {
                            age = year - 1947;
                            str = '<p><strong>Age in 1947: </strong>N/A (born in ' + year + ')</p>';
                        }
                        resultsListInfo += str;
                    } else {
                        str = '<p><strong>Age in 1947: </strong>age not available</p>';
                        resultsListInfo += str;
                    }

                    if (total[i].migrated_from_longitude_coordinates == total[i].migrated_to_longitude_coordinates && total[i].migrated_from_latitude_coordinates == total[i].migrated_to_latitude_coordinates) {
                        str = '<p><strong>Location in 1947: </strong>' + total[i].migrated_from_village_city_state_country + ' (did not migrate)</p>';
                        resultsListInfo += str;
                    } else {
                        if (total[i].migrated_from_village_city_state_country) {
                            str = '<p><strong>Migrated from: </strong>' + total[i].migrated_from_village_city_state_country + '</p>';
                            resultsListInfo += str;
                        }
                        if (total[i].migrated_to_village_city_state_country) {
                            str = '<p><strong>Migrated to: </strong>' + total[i].migrated_to_village_city_state_country + '</p>';
                            resultsListInfo += str;
                        }
                    }

                    pictureLeng = pictures.length;
                    var resultsImgCont = '';

                    if (pictureLeng > 0) {
                        resultsImgCont = "<div class='cdb1947-interviewee-img' style='display:block;'>" +
                            "<div class='cdb1947-picbadge'>" +
                            "<div class='cdb1947-badge'>" + pictures.length + "</div>" +
                            "</div>" +
                            "<img src='" + initialImage + "' />" +
                            "</div>";
                    } else {
                        resultsImgCont = "<div class='cdb1947-interviewee-img' style='display:block;'></div>";
                    }

                    var fancyboxHTMLouter = "<div class='fancy-wrap'>" +
                        fancyboxHTML + "<p class='story-summary'>" + total[i].story_summary_curated.replace(/'/g, "&apos;").replace(/"/g, "&quot;") + "</p>" +
                        "</div>";

                    var routeUrl = window.location.origin + window.location.pathname + '\/#\/story\/' + total[i].interview_number;

                    if (total[i].video_link) {
                        resultsListWrap = resultsListWrap +
                            '<a class="search-result-item cdb1947-video-icon" id="' + total[i].interview_number + '" href="#">' +
                            resultsImgCont + '<div class="cdb1947-content">' + resultsListInfo + '</div></a>' +
                            fancyboxHTMLouter;
                    } else {
                        resultsListWrap = resultsListWrap +
                            '<a class="search-result-item" id="' + total[i].interview_number + '" href="#">' +
                            resultsImgCont + '<div class="cdb1947-content">' + resultsListInfo + '</div></a>' +
                            fancyboxHTMLouter;
                    }

                    $("#cdb1947-result-listing ul").append("<li class='cdb1947-dataWrapper'><div class='cdb1947-data'>" + resultsListWrap + "</div></li>");

                }

                $('.search-result-item').click(function() {

                    var id = this.id;

                    $state.go('home.story', {
                        interview_number: id
                    });

                });

                $("div.cdb1947-holder").jPages({
                    containerID: "itemContainer",
                    perPage: 10,
                    callback: function(pages, items) {
                        $(".cdb1947-result-listing-wrapper").scrollTop(0);
                    }
                });
                me.updateStoriesAvailable(total.length, me.totalCount);

            };

            this.runNewSqlQuery = function(data, field, order) {
                var query,
                    locField = me.fieldMap[me.view].city;

                field = (field) ? field : 'interviewee';
                order = (order) ? order : 'asc';

                if (typeof(data) == "object") {
                    query = "SELECT * FROM stories_w_current WHERE " + data.field + " = '" + data.value + "'";
                    if (data.field == me.fieldMap.view1.city || data.field == me.fieldMap.view2.city) {
                        $("select option:contains('" + data.value + "')").prop('selected', true);
                    }
                    $('#cdb1947-search-result').show();
                } else {
                    query =
                        "SELECT * FROM " + this.cdbTablename + " " +
                        "WHERE " + locField + " = '" + data + "' " +
                        "ORDER BY " + field + " " + order;
                    me.selectedCity = data;
                }

                me.sql.execute(query)
                    .done(function(results) {
                        me.closeSnapshotInfoWindow();
                        if (me.resultHasData(results)) {
                            me.newSqlQueryDone(results);
                        }
                    })
                    .error(function(errors) {
                        console.log("error:" + errors);
                    });
            };

            this.newSqlQueryDone = function(results) {
                var snapshotQuery,
                    viewFields = me.fieldMap[me.view],
                    firstResult = results.rows[0];

                if (firstResult[viewFields.snapshot]) {
                    snapshotQuery = me.cdbMapQuery(
                        null,
                        firstResult.cartodb_id,
                        "INNER"
                    );

                    me.sendSnapshotQuery(firstResult, snapshotQuery, viewFields);
                }

                me.populateSearchResults(results);
            };

            this.sendSnapshotQuery = function(firstResult, snapshotQuery, viewFields) {
                var latlng;
                $.ajax({
                    url: 'http://archive-1947.cartodb.com/api/v2/sql?q=' + snapshotQuery,
                    success: function(ajaxResult) {
                        latlng = [
                            firstResult[viewFields.lat],
                            firstResult[viewFields.lon]
                        ];
                        me.createSnapshotInfoWindow(latlng, ajaxResult.rows[0]);
                    },
                    error: function(err) {
                        console.error(err.responseText);
                    }
                });
            };

            this.getTotalStoryCount = function(callback) {
                me.sql.execute("SELECT COUNT(*) FROM " + this.cdbTablename + ";")
                    .done(callback);
            };

            this.onNonClusterHover = function(ev, latlng, pos, data) {

                var storiesLabel = ' Story',
                    sqlCount = new cartodb.SQL({
                        user: 'archive-1947'
                    }),
                    cityField = me.fieldMap[me.view].city,
                    query =
                        "SELECT DISTINCT " + cityField + " " +
                        "AS groupedcity, count(*) AS countit " +
                        "FROM stories_w_current WHERE " + cityField + " = '" + data.city + "' " +
                        "GROUP BY " + cityField + "";

                sqlCount.execute(query)
                    .done(function(results) {
                        me.createHoverCallout(results, storiesLabel, pos);
                    })
                    .error(function(err) {
                        console.error(err);
                    });

            };

            this.createHoverCallout = function(results, storiesLabel, pos) {
                if (me.resultHasData(results)) {

                    var groupedCityName = results.rows[0].groupedcity,
                        cityCount = results.rows[0].countit,
                        calloutHtml;

                    pos = me.hoverCalloutOffset(pos, me.containerDiv.offset());

                    if (cityCount > 1) {
                        storiesLabel = ' Stories';
                    }

                    calloutHtml =
                        '<div>' + '<strong>' + groupedCityName + '</strong><br>' +
                        cityCount + storiesLabel + '</div>';

                    $('#cdb1947-infopane')
                        .offset({
                            left: pos.x + 8,
                            top: pos.y + 12
                        })
                        .html(calloutHtml)
                        .show();
                }
            };

            this.hoverCalloutOffset = function(pos, containerObj) {
                if (containerObj) {
                    pos.y = (containerObj.top) ? pos.y + containerObj.top : pos.y;
                    pos.x = (containerObj.left) ? pos.x + containerObj.left : pos.x;
                } else {
                    pos.y = pos.y;
                    pos.x = pos.x;
                }
                return pos;
            };

            this.cdbMapQuery = function(partial, cdbId) {

                var query, viewFields = me.fieldMap[me.view],
                    snapField = viewFields.snapshot || 'snap_from_id',
                    whereClause = " ";

                if (partial) {
                    return "WHERE " + viewFields.lat + " IS NOT NULL ";
                }

                if (cdbId) {
                    whereClause += "WHERE stories_w_current.cartodb_id = " + cdbId + " ";
                }

                query =
                    "SELECT 'stories_w_current' AS layer, " +
                    "CDB_TransformToWebmercator(ST_SetSRID(ST_Point(stories_w_current." +
                    viewFields.lon + ", " + "stories_w_current." +
                    viewFields.lat + "),4326)) as the_geom_webmercator, " +
                    "COUNT(*) as sz, " +
                    "stories_w_current." + viewFields.city + " AS city, " +
                    "stories_w_current." + viewFields.lat + " AS lat, " +
                    "stories_w_current." + viewFields.lon + " AS lon, " +
                    "stories_w_current.cartodb_id, " +
                    "snapshots.body AS snap_body, " +
                    "snapshots.snap_id AS snap_id, " +
                    "snapshots.credit_src AS snap_cred_src, snapshots.credit_url AS snap_cred_url, " +
                    "snapshots.img_url AS snap_img_url, snapshots.heading AS snap_heading " +
                    "FROM stories_w_current " +
                    "LEFT JOIN snapshots ON stories_w_current." + snapField + "=snapshots.snap_id" +
                    whereClause +
                    "GROUP BY city, lat, lon, " +
                    "stories_w_current.cartodb_id, stories_w_current.the_geom_webmercator, " +
                    "snap_heading, snap_body, snap_id, snap_cred_src, snap_cred_url, snap_img_url";

                return query;
            };

            // Migrated from, to, or current city
            this.setGlobalMigrationType = function(id) {

                var legendClass = me.fieldMap[id].legendClass,
                    NonClusters = me.nonClusterSubLayer,
                    Clusters = me.clusterSubLayer,
                    clusterCss, clusterSql,
                    baseClusterCss = me.clusterStyles.base || getBaseCss();

                /* Housekeeping resets */
                $('#cdb1947-search-result').hide();
                me.clearPolyLines();
                me.closeSnapshotInfoWindow();
                $('.legenditems > label').removeClass('cdb1947-selected');

                me.view = id;

                $('#' + id).addClass('cdb1947-selected');

                // Update legend
                $('.legend-panel-symbols > .marker-cluster')
                    .removeClass('mig--from mig--to mig--cur')
                    .addClass('mig--' + legendClass);

                // Set css, sql on both sublayers
                clusterCss = me.clusterStyles[legendClass] || getClusterCss(legendClass);
                clusterSql = me.clusterQueries[legendClass] || getClusterQuery(legendClass, me.cdbMapQuery(true));

                NonClusters.setSQL(me.cdbMapQuery());
                NonClusters.setCartoCSS(getNonClusterCss());

                Clusters.setSQL(clusterSql);
                Clusters.setCartoCSS(clusterCss);

                me.prepDataExtentQuery();
                me.prepCityDropdownQuery();
                me.getAutoCompleteData();
            };

            this.prepDataExtentQuery = function() {
                var viewFields = me.fieldMap[me.view],
                    query =
                    "SELECT " + viewFields.lat + " AS latnow, " + viewFields.lon + " AS lonnow " +
                    "FROM stories_w_current";

                me.sql.execute(query)
                    .done(function(data) {
                        me.dataExtentQueryDone(data);
                    })
                    .error(function(err) {
                        console.error(err);
                    });

                return;
            };

            this.dataExtentQueryDone = function(data) {
                var bounds = new google.maps.LatLngBounds(),
                    markers = [], markerCluster;

                if (me.resultHasData(data)) {
                    data.rows.forEach(function(row) {
                        var coords = [new google.maps.LatLng(row.latnow, row.lonnow)];
                        bounds.extend(coords[0]);
                    });
                    me.map.fitBounds(bounds);
                    if (me.view === "view3") {
                        me.map.setCenter(new google.maps.LatLng(24.8918157243493, 18.3251625));
                    }
                    me.updateStoriesAvailable(null, data.rows.length);
                }
            };

            me.containerDiv.append('<div id="cdb1947-infopane"></div>');

            // Create a new StyledMapType object, passing it the array of styles
            var gmStyleArray = [{
                featureType: "all",
                stylers: [{
                    saturation: -80
                }]
            }, {
                featureType: "road.arterial",
                elementType: "geometry",
                stylers: [{
                    hue: "#00ffee"
                }, {
                    saturation: 50
                }]
            }, {
                featureType: "poi.business",
                elementType: "labels",
                stylers: [{
                    visibility: "off"
                }]
            }];

            this.map = new google.maps.Map(me.mapDiv[0], {
                center: new google.maps.LatLng(21, 90),
                disableDoubleClickZoom: false,
                draggable: true,
                mapTypeControl: this.mapTypeControl || false,
                mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style'],
                maxZoom: 18,
                minZoom: 2,
                streetViewControl: this.streetViewControl || false,
                styles: gmStyleArray,
                zoom: this.zoom || 4,
                zoomControl: false
            });

            // Options for both cluster and non
            this.layerOptions = {
                type: 'cartodb',
                user_name: this.cdbUsername,
                table_name: this.cdbTablename,
                sublayers: [{
                    cartocss: getNonClusterCss(),
                    interactivity: "cartodb_id,city,snap_heading,snap_body,snap_cred_src,snap_cred_url,snap_img_url",
                    sql: me.cdbMapQuery()
                }, {
                    cartocss: getClusterCss('from'),
                    interactivity: "cartodb_id,points_count",
                    sql: getClusterQuery(me.cdbMapQuery(true))
                }]
            };

            // Cluster layer added first
            cartodb.createLayer(this.map, this.layerOptions)
                .addTo(this.map, 0)
                .done(function(layer) {

                    me.layer = layer;

                    // Add interaction
                    me.nonClusterSubLayer = layer.getSubLayer(0);
                    me.clusterSubLayer = layer.getSubLayer(1);
                    me.nonClusterSubLayer.setInteraction(true);
                    me.clusterSubLayer.setInteraction(true);
                    addCursorInteraction(layer);

                    // Events
                    me.clusterSubLayer.on('featureClick', function(e, latlng, pos, data, layer) {
                        clusterClick(e, latlng);
                    });
                    me.nonClusterSubLayer.on('featureClick', me.nonClusterClick);
                    me.nonClusterSubLayer.on('featureOver', me.nonClusterHover);
                    me.nonClusterSubLayer.on('featureOut', me.nonClusterHoverOut);

                }).error(function(err) {
                    throw err;
                });

            // Clear lines and close infowindow whenever map is clicked
            this.map.addListener('click', function() {
                me.clearPolyLines();
                me.closeSnapshotInfoWindow();
            });

            // Cursor pointer on hover
            function addCursorInteraction(layer) {
                var hovers = [];
                layer.bind('featureOver', function(e, latlon, pxPos, data, layer) {
                    hovers[layer] = 1;
                    if (_.any(hovers)) {
                        $('#cdb1947-map > .gm-style > div').css('cursor', 'pointer');
                    }
                });
                layer.bind('featureOut', function(m, layer) {
                    hovers[layer] = 0;
                    if (!_.any(hovers)) {
                        $('#cdb1947-map > .gm-style > div').css('cursor', 'auto');
                    }
                });
            }

            function getClusterQuery(view, whereClause) {

                var returnData,
                    url = popupFactory.getCorrectPath('spatial/sql-cluster-query.html');

                $.ajax({
                    url: url,
                    async: false,
                    success: function(data) {
                        // Replace placeholder with WHERE clause
                        returnData = data.replace(/--JS_PLACEHOLDER/g, whereClause);
                        // Easy alias for later
                        me.clusterQueries[view] = returnData;
                    }
                });
                return returnData;
            }

            function getClusterCss(view) {

                // Include base
                var baseClusterCss = me.clusterStyles.base || getBaseCss(),
                    vars = me.clusterVars[view],
                    preppedVars = '' +
                    '@c-smallest: #' + vars[0] + ';\n ' +
                    '@c-smalls: #' + vars[1] + ';\n ' +
                    '@c-midsmalls: #' + vars[2] + ';\n ' +
                    '@c-mids: #' + vars[3] + ';\n ' +
                    '@c-bigs: #' + vars[4] + ';\n\n',
                    returnData = preppedVars + baseClusterCss;

                // Easy alias for later
                me.clusterStyles[view] = returnData;

                return returnData;
            }

            function getBaseCss() {
                var url = popupFactory.getCorrectPath('spatial/clusters--base.html'),
                    returnData;

                $.ajax({
                    url: url,
                    async: false,
                    success: function(data) {
                        returnData = data;
                        me.clusterStyles.base = data; // Easy alias for later
                    }
                });

                return returnData;
            }

            function getNonClusterCss() {
                var Color = me.fieldMap[me.view].color,
                    css = '#' + me.cdbTablename + ' { ' +
                        'marker-allow-overlap: true; ' +
                        'marker-fill: ' + Color + '; ' +
                        'marker-width: 12; ' +
                        'marker-line-width: 1.5; ' +
                        'marker-line-color: #eee; ' +
                        'marker-fill-opacity: 1; ' +
                    '}';

                return css;
            }

            this.nonClusterHover = function(ev, latlng, pos, data) {
                me.onNonClusterHover(ev, latlng, pos, data);
                me.map.setOptions({
                    draggableCursor: 'pointer'
                });
                $('#cdb1947-infopane').css('opacity', '1');
            };

            this.nonClusterHoverOut = function() {
                $('#cdb1947-infopane').css('opacity', '0');
                me.map.setOptions({
                    draggableCursor: ''
                });
            };

            this.nonClusterClick = function(ev, latlng, pos, data) {
                me.runNewSqlQuery(data.city, '', '');

                $("select option:contains('" + data.city + "')").prop('selected', true);

                if ($('select option:selected').text() !== 'all') {
                    $('#cdb1947-search-result').show();
                } else {
                    $('#cdb1947-search-result').hide();
                }
            };

            this.createSnapshotFancybox = function(data, imgPath) {
                var snapField = me.fieldMap[me.view].snapshot;

                $('#snapshot-fancybox').remove();
                $('body').append(
                    '<div id="snapshot-fancybox" style="display: none;">' +
                        '<div class="fancy-wrap fancybox--snapshot">' +
                            '<img src="" id="snap-fancybox-img">' +
                            '<h2 class="fancybox__heading">' + data.snap_heading + '</h2>' +
                            '<p>' + data.snap_body + '</p>' +
                            '<a href="http://wrocah.ac.uk/" target="_blank" title="White Rose College of the Arts & Humanities">'+
                                '<img src="http://1947partitionarchive.org/sites/default/files/MapPhotos/Snapshots/WR_AHRC_Logo.svg" class="partner-logo">'+
                            '</a>' +
                        '</div>' +
                    '</div>');

                $('#snapshot__read-more').fancybox({
                    close: function() {}
                });

                $('#snap-fancybox-img').attr('src', imgPath);
            };

            this.createSnapshotContent = function(data) {
                var content =
                    '<div id="iw-container">' +
                        '<div class="iw-title">' + data.snap_heading + '</div>' +
                        '<div class="iw-content">' +
                            '<p>' + data.snap_body.substr(0, 64) + '...<a href="#snapshot-fancybox" id="snapshot__read-more" class="iw__read-more">Read More</a></p>' +
                        '</div>' +
                    '</div>',
                    imgPath = 'http://1947partitionarchive.org/sites/default/files/MapPhotos/Snapshots/' +
                    data.snap_id + '.jpg';

                return {
                    content: content,
                    imgPath: imgPath
                };
            };

            this.snapShotInfoWindow = new google.maps.InfoWindow();

            /**
             * Create Google Maps InfoWindow with city 'Snapshot'
             * @param  {array} latlng Latitude, longitude.
             * @param  {object} data   Contains snap_body and snap_heading.
             */
            this.createSnapshotInfoWindow = function(latlng, data) {
                var snapshotContent = me.createSnapshotContent(data);

                me.snapShotInfoWindow.setOptions({
                    content: snapshotContent.content,
                    position: {
                        lat: latlng[0],
                        lng: latlng[1]
                    },
                    maxWidth: 350
                });
                me.snapShotInfoWindow.open(me.map);
                adjustInfoWindowStyle();
                $('.gm-style-iw').css('background-image', 'url(' + snapshotContent.imgPath + ')');

                me.createSnapshotFancybox(data, snapshotContent.imgPath);
            };

            this.closeSnapshotInfoWindow = function(argument) {
                if (me.snapShotInfoWindow) {
                    me.snapShotInfoWindow.close();
                }
            };

            /* "Read More..." Snapshot link to lightbox. */
            $('#cdb1947-map').on('click', '.iw__read-more', function(event) {
                event.preventDefault();
            });

            /*
                CREDIT: Miguel Marnoto
                http://en.marnoto.com/2014/09/5-formas-de-personalizar-infowindow.html
            */
            function adjustInfoWindowStyle(){

                var $iwOuter = $('.gm-style-iw'), // DIV that wraps bottom of infowindow

                    /* Since this div is in a position prior to .gm-div style-iw.
                     * We use jQuery and create a $iwBackground variable,
                     * and took advantage of the existing reference .gm-style-iw
                     * for the previous div with .prev().
                     */
                    $iwBackground = $iwOuter.prev(),

                    // div that groups the close button elements.
                    $iwCloseBtn = $iwOuter.next();

                $iwBackground.children(':nth-child(1)').css({
                    'display': 'block'
                });

                // Remove white background and shadow DIVs
                $iwBackground.children(':nth-child(2), :nth-child(4)').hide();

                // Changes the desired tail shadow color.
                $iwBackground.children(':nth-child(3)').find('div').children().css({
                    'box-shadow': 'hsl(0, 0%, 21%) 0px 1px 1px',
                    'background-color': 'hsl(34, 7%, 39%)',
                    'z-index': '1'
                });

                // Apply the desired effect to the close button
                $iwCloseBtn
                    .css({
                        'border-radius': '13px',
                        border: '4px solid hsla(8, 100%, 23%, .8)',
                        opacity: '1',
                        right: '38px',
                        top: '6px'
                    })
                    .attr('title', 'Close this dialog');

                // The API automatically applies 0.7 opacity to the button after the mouseout event.
                // This function reverses this event to the desired value.
                $iwCloseBtn.mouseout(function() {
                    $(this).css({
                        opacity: '1'
                    });
                });
            }

            function clusterClick(ev, latlng) {
                // Close popup if needed
                me.nonClusterHoverOut();
                me.map.setZoom(me.map.getZoom() + 1);
                me.map.setCenter({
                    lat: latlng[0],
                    lng: latlng[1]
                });
            }

            this.prepCityDropdownQuery();

            this.getTotalStoryCount(function(res) {
                me.totalCount = res.rows[0].count;
                me.updateStoriesAvailable(null, me.totalCount);
            });

            $('#cdb1947-search-result').hide();


            /*********************************************************/
            /*********************** MAP CONTROLS ********************/
            /*********************************************************/

            $('#zoom-in').click(function() {
                if (me.map.getZoom() < me.map.maxZoom) {
                    me.map.setZoom(me.map.getZoom() + 1);
                }
            });

            $('#zoom-out').click(function() {
                if (me.map.getZoom() > me.map.minZoom) {
                    me.map.setZoom(me.map.getZoom() - 1);
                }
            });

            $('#zoom-home').click(function() {
                me.map.setZoom(4);
                me.map.setCenter(new google.maps.LatLng(21, 90));
            });

            $('#toggle-fullscreen').click(function() {
                $('#cdb1947-container, body').toggleClass('fullscreen-fixed');

                // Toggle fullscreen icon
                var xlink = $(this).find('use'),
                    href = popupFactory.getCorrectPath('img/svg-defs.svg#icon-fullscreen');

                if ($('#cdb1947-container').hasClass('fullscreen-fixed')) {
                    href = popupFactory.getCorrectPath('img/svg-defs.svg#icon-fullscreen-exit');
                }
                console.log(href);
                xlink.attr('xlink:href', href);

                google.maps.event.trigger(me.map, 'resize');
            });

            $('#toggle-legend').click(function() {
                $('#legend-panel').toggleClass('visible');
            });

        };

        var obj = new cdb1947.map({
            mapId: 'cdb1947-map',
            contentId: 'cdb1947-container',
            center: new google.maps.LatLng(18, 120)
        });

        obj.getAutoCompleteData();

        $('.asc_btn_name').click(function(event) {
            event.preventDefault();
            var v = $(this).attr('value');
            if (v == "city" && obj.view == "view1") {
                v = "migrated_to_village_city_state_country";
            } else if (v == "city" && obj.view == "view2") {
                v = "migrated_from_village_city_state_country";
            }
            var order = $(this).attr('order');
            order = (order == 'asc') ? 'desc' : 'asc';
            obj.runNewSqlQuery(obj.selectedCity, v, order);
            $(this).attr('order', order);
        });

        $('.img-fancybox').fancybox({
            afterClose: function() {
                history.back();
            }
        });

        // Migrated from, to, and current btns
        $('.cdb1947-legend').click(function() {
            obj.setGlobalMigrationType(this.id);
        });

        // Tooltips over map ctrls
        $('.custom-map-ctrl').tooltip({
            position: {
                my: "left+7 center",
                at: "right center"
            },
            tooltipClass: 'tooltip--map-ctrl'
        });

        $('.legend-close-btn').click(function() {
            $('#legend-panel').removeClass('visible');
        });


        // Ghetto fix for xlink-hrefs on live vs. local due to Drupal path
        // Results in a console error when files initially are not found,
        // but this shouldn't occur on live site.

        var customCtrls = $('.custom-map-ctrls use'),
            origPath,
            relModulePath = 'sites/all/modules/custom/story_map/',
            replacementStr = relModulePath;

        if (location.hostname.indexOf('1947partitionarchive') < 0) {
            replacementStr = '';
        }

        for (var i = 0, max = customCtrls.length; i < max; i++) {
            origPath = $(customCtrls[i]).attr('xlink:href').replace(relModulePath, replacementStr);
            $(customCtrls[i]).attr('xlink:href', origPath);
        }

    }

})();
