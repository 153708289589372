(function() {

    'use strict';

    angular
        .module('popupsModule', []);

    angular.module('mapApp', [
            'popupsModule',
            'ui.router',
        ])
        .run(['$rootScope', '$state', '$stateParams', '$location',
            function ($rootScope, $state, $stateParams, $location) {

                $('head').append('<meta name="fragment" content="!">');

            }
        ]);

})();
