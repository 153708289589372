/* Popup Factory */
(function() {

    'use strict';

    angular
    .module('popupsModule')
    .factory('popupFactory', popupFactory);

    popupFactory.$inject = ['$rootScope', '$http', '$location', '$sce'];

    function popupFactory($rootScope, $http, $location, $sce){

        var factory = {
            configPicsFancybox: configPicsFancybox,
            encodeImg: encodeImg,
            fbShareDialog: fbShareDialog,
            getCurrentUrl: getCurrentUrl,
            getCorrectPath: getCorrectPath,
            PopupCenter: PopupCenter,
            setBirthYear: setBirthYear,
            setMigrationStatus: setMigrationStatus,
            setShareUrl: setShareUrl,
            trustMedia: trustMedia,
        };

        /* Calculate age in 1947 */
        function setBirthYear(year){

            var str, age = 0;
            if ( year < 1947 ) {
                age  = 1947 - year;
                str = age;
            } else {
                age = year - 1947;
                str = 'N/A (born in ' + year + ')';
            }

            return str;

        }

        /* Determine migration status */
        function setMigrationStatus(data){

            if (data.migrated_from_longitude_coordinates == data.migrated_to_longitude_coordinates && data.migrated_from_latitude_coordinates == data.migrated_to_latitude_coordinates){
                return false;
            } else {
                return true;
            }

        }

        /* Set up sub-fancybox for pics */
        function configPicsFancybox(data){

            var imgArr = [], colName;

            for (var n = 1; n < 5; n++) {
                colName = 'interviewer_photo_link_' + n;
                if (data[colName]) {
                    imgArr.push({
                        url: data[colName],
                        alt: data.interviewee + ' photo ' + n,
                    });
                }
            }

            return imgArr;

        }

        /* Encode image for urlShareParams, if present */
        function encodeImg(img){
            if (img){
                return encodeURIComponent(img);
            } else {
                return false;
            }
        }

        /* FB Share Prompt */
        function fbShareDialog(intNum) {

            FB.ui({
              method: 'share',
              href: getCurrentUrl(intNum),
              name: "Your Page Title",
              link: getCurrentUrl(intNum),
              picture: "https://stackexchange.com/users/flair/557969.png",
              caption: "Some description here"
            }, function(response){});

         }

        /* Center popup window, even on dual monitor */
        function PopupCenter(url, title, w, h) {
            // Fixes dual-screen position                         Most browsers      Firefox
            var dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : screen.left;
            var dualScreenTop = window.screenTop !== undefined ? window.screenTop : screen.top;

            var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
            var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

            var left = ((width / 2) - (w / 2)) + dualScreenLeft;
            var top = ((height / 2) - (h / 2)) + dualScreenTop;
            var newWindow = window.open(url, title, 'width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

            // Puts focus on the newWindow
            if (window.focus) {
                newWindow.focus();
            }
        }

        /* Drupal has different paths, can't do relative */
        function getCorrectPath (relPath) {

            var correctPath;

            // For production only
            if (location.hostname.indexOf('1947partitionarchive') > -1){
                correctPath = 'sites/all/modules/custom/story_map/' + relPath;
            } else {
                correctPath = relPath;
            }

            return correctPath;

        }

        /* Get correct URL, even if via FB */
        function getCurrentUrl(intNum, php){

            var url;

            if (php){

                url = '' +
                    $location.$$protocol + '://' +
                    $location.$$host + ':' +
                    $location.$$port +
                    '/sites/all/modules/custom/story_map/static-page.php?id=' + intNum;

            } else {

                url = $location.$$absUrl;

                // Get rid of Facebook's junk
                if (url.indexOf('?fb_ref=Default') > 1){
                    url.replace('?fb_ref=Default', '');
                }

            }

            return url;

        }

        /* Social links */
        function setShareUrl(medium, params) {

            var shareUrl = {
                email: "mailto:?subject=" + params.name + "'s story (via 1947 Partition Archive)" +
                    "&body=I'd like to share this story from The 1947 Partition Archive: " +
                    params.url + '%0A%0A' +
                    '----------------------------------------------------------------' +
                    '----------------------------------------------------------------%0A%0A' +
                    encodeURIComponent(params.description),
                google: 'https://plus.google.com/share?url=' + params.url,
                reddit: 'http://reddit.com/submit?url=' + params.url + '&title=' +
                    encodeURIComponent(params.name + '\'s Story | The 1947 Partition Archive'),
                link: '#',
                pinterest: 'http://pinterest.com/pin/create/button/?url=' +
                    encodeURIComponent(params.url) +
                    '&media=' + params.img +
                    '&description=' + params.description,
                twitter: 'https://twitter.com/intent/tweet?text=' +
                    params.description.substr(0,79) + '...' +
                    '&url=' + encodeURIComponent(params.url) +
                    '&via=1947Partition',
                facebook: 'https://www.facebook.com/sharer/sharer.php?' +
                    'u=' + encodeURIComponent(params.url)
            };

            return shareUrl[medium];

        }

        /* Trust video */
        function trustMedia (url){
            if (url.toLowerCase().indexOf('youtube') > -1) {
                url = url.replace('watch?v=', 'embed/');
            } else {
                url = url.replace('//vimeo.com/', '//player.vimeo.com/video/');
            }
            return $sce.trustAsResourceUrl(url);
        }

        return factory;

    }


})();
