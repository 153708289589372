(function() {

    'use strict';

    angular
        .module('popupsModule')
        .controller('PopupCtrl', PopupCtrl);

    PopupCtrl.$inject = ['$timeout', 'popupFactory', '$rootScope', 'selFeatData', '$state', '$http', '$compile', '$scope'];

    function PopupCtrl($timeout, popupFactory, $rootScope, selFeatData, $state, $http, $compile, $scope){

        var vm = this;
        var $ = jQuery.noConflict();

        /********** DATA FOR SELECTED FEATURE **********/
        vm.selFeatData = selFeatData.rows[0];

        var urlShareParams = {
            caption: 'Story Map | The 1947 Partition Archive',
            description: vm.selFeatData.story_summary_curated,
            img: popupFactory.encodeImg(vm.selFeatData.interviewer_photo_link_1),
            intNum: vm.selFeatData.interview_number,
            name: vm.selFeatData.interviewee,
            url: popupFactory.getCurrentUrl(vm.selFeatData.interview_number),
        };

        // Social links
        vm.socialLinkList = [
            {
                name: 'facebook',
                caption: 'Share this story on Facebook',
                icon: popupFactory.getCorrectPath('img/svg-defs.svg#icon-facebook'),
                target: '_blank',
                url: '',
                click: function(){
                    var left = (screen.width/2)-(300);
                    var top = (screen.height/2)-(300);
                    MyWindow = window.open(popupFactory.setShareUrl('facebook', urlShareParams),
                        'MyWindow',
                        'width=600,height=600,top=' + top + ',left=' + left);
                    return false;
                }
            },
            {
                name: 'twitter',
                caption: 'Tweet this story on Twitter',
                icon: popupFactory.getCorrectPath('img/svg-defs.svg#icon-twitter'),
                url: '',
                click: function(){
                    var left = (screen.width/2)-(300);
                    var top = (screen.height/2)-(150);
                    MyWindow = window.open(popupFactory.setShareUrl('twitter', urlShareParams),
                        'MyWindow',
                        'width=600,height=300,top=' + top + ',left=' + left);
                    return false;
                },
                target: '_blank',
            },
            {
                name: 'google',
                caption: 'Share this story on Google Plus',
                icon: popupFactory.getCorrectPath('img/svg-defs.svg#icon-google'),
                url: popupFactory.setShareUrl('google', urlShareParams),
                target: '_blank',
            },
            {
                name: 'reddit',
                caption: 'Share this story on Reddit',
                icon: popupFactory.getCorrectPath('img/svg-defs.svg#icon-reddit'),
                target: '_blank',
                url: popupFactory.setShareUrl('reddit', urlShareParams),
            },
            {
                name: 'pinterest',
                caption: 'Pin this story on Pinterest',
                icon: popupFactory.getCorrectPath('img/svg-defs.svg#icon-pinterest'),
                url: popupFactory.setShareUrl('pinterest', urlShareParams),
                target: '_blank',
            },
            {
                name: 'email',
                caption: 'Email this story',
                icon: popupFactory.getCorrectPath('img/svg-defs.svg#icon-email'),
                url: popupFactory.setShareUrl('email', urlShareParams),
                target: '_blank',
            },
            {
                name: 'link',
                caption: 'Direct link to this story',
                icon: popupFactory.getCorrectPath('img/svg-defs.svg#icon-link'),
                url: '',
                target: '_self',
                click: function(){
                    vm.showLinkContainer = !vm.showLinkContainer;
                }
            },
        ];

        /* Calculate age in 1947 */
        if (vm.selFeatData.interviewee_date_of_birth){
            vm.age = popupFactory.setBirthYear(vm.selFeatData.interviewee_date_of_birth);
        } else {
            vm.age = 'age not available';
        }

        vm.migrated = popupFactory.setMigrationStatus(vm.selFeatData);

        /* The main Fancybox */
        vm.openFancybox = function () {

            var templatePath = 'sites/all/modules/custom/story_map/html/template.popup.html';

            // For dev only
            if (location.hostname === "45.55.16.75" || location.hostname === "localhost") {
                templatePath = 'html/template.popup.html';
            }

            $('.fancybox').fancybox({
                topRatio: 0.1,
                maxWidth: 800,
            });

            $http.get(templatePath).then(function(response) {
                if (response.status == 200) {

                    var template = angular.element(response.data);

                    var compiledTemplate = $compile(template)($scope);

                    $.fancybox.open(compiledTemplate, {
                        afterClose: function(){
                            $state.go('home', {reload: true});
                        }
                    });

                }
            });

        };

        /* Set thumbs for ng-repeat */
        vm.thumbs = popupFactory.configPicsFancybox(vm.selFeatData);

        /* Get current URL for link button */
        vm.getCurrentUrl = urlShareParams.url;

        /***** Trust video URLs *****/
        if (vm.selFeatData.video_link) {
            vm.videoUrl = popupFactory.trustMedia(vm.selFeatData.video_link);
        }

        vm.openFancybox();

    }

})();

// });