(function() {

    'use strict';

    angular.module('mapApp')
    .config(['$stateProvider', '$urlRouterProvider', '$locationProvider',

    function($stateProvider, $urlRouterProvider, $locationProvider) {

        /* Redirect if URL not found */
        $urlRouterProvider.otherwise('/');

        /* Set base */
        $('head').append('<base href="/"/>');

        /* Enable HTML5 mode */
        $locationProvider.html5Mode(true);
        $locationProvider.hashPrefix('!');

        /* Configure routes*/
        $stateProvider
        .state('home', {
            url: '/',
            template: '<div ui-view></div>',
        })
        .state('home.story', {
            url: 'story/:interview_number',
            template: '<div></div>',
            controller: 'PopupCtrl',
            controllerAs: 'vm',
            resolve: {
                selFeatData: ['$http', '$stateParams', function($http, $stateParams) {

                    var query = 'https://archive-1947.cartodb.com/api/v2/sql?q=' +
                        ' SELECT * FROM stories_w_current WHERE interview_number = ' +
                        $stateParams.interview_number;

                    return $http.get(query).then(function(response){
                        return response.data;
                    });

                }],
            },
        });

    }]);

})();
